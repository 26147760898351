import React from 'react';
import './VendorsBox.css';

const VendorsBox = () => {
  return (
    <div className="vendors-container">
      <div className="vendors-box">
        Vendors
      </div>
    </div>
  );
};

export default VendorsBox;
